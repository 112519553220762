import React from 'react'

const Footer = () => (
  <div className="footer_area">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="footer_content">
            <p>Copyright © 2017. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Footer