import React from 'react'
import { FaUser, FaPhone } from 'react-icons/fa';

const Header = () => (
  <div className="header_top" id="top">
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-md-5 col-sm-6">
          <p>
            <span><FaUser/>info@objectit.com.au</span>
            <span><FaPhone/>+61 7 3102 8324</span>
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default Header
